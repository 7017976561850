.image-gallery-slide {
  overflow: hidden;
}

img.image-gallery-image {
  transition: all 0.3s ease 0s;
  width: 100%;
}
img.image-gallery-image:hover {
  transform: scale(1.25);
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: auto;
  line-height: 0;
  height: 93px;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(60vh - 60px);
}
