.css-1453shb-MuiButtonBase-root-MuiButton-root {
  border-radius: 30px !important;
}

.css-2xif66-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px !important;
  padding-left: 0px !important;
}

.listItem:active {
  padding-left: 6px !important;
  color: blue;
}
