.css-1vg82n2-MuiButtonBase-root-MuiButton-root {
  border-radius: 1px !important;
}
.agency:hover > .a1 {
  top: 0;
  height: 15vh !important;
  transition: all 0.5s;
  opacity: 1;
  z-index: 4;
}
.agency:hover > .a2 {
  opacity: 1;
  z-index: 5;
  transition: all 0.5s;
}
.agency:hover > .a3 {
  opacity: 1 !important;
  z-index: 5;
  transition: all 0.5s;
}
/* .css-1j58dwq-MuiButtonBase-root-MuiButton-root {
  border-radius: 30px !important;
} */
.css-15c3mdk-MuiButtonBase-root-MuiButton-root {
  border-radius: 30px !important;
}
.verificationContainer:hover > .verification {
  opacity: 1;
  transition: opacity 0.5s;
}
