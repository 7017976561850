.circle-ripple {
  background-color: #35ffc3;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  animation: ripple 0.7s linear infinite;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(2, 207, 170, 0.3),
      0 0 0 12px rgba(2, 207, 170, 0.3), 0 0 0 20px rgba(2, 207, 170, 0.3),
      0 0 0 28px rgba(2, 207, 170, 0.3);
  }
  100% {
    box-shadow: 0 0 0 12px rgba(2, 207, 170, 0.3),
      0 0 0 20px rgba(2, 207, 170, 0.3), 0 0 0 28px rgba(2, 207, 170, 0.3),
      0 0 0 40px rgba(2, 207, 170, 0);
  }
}

@keyframes ldio-japzwhp0h9j {
  0% {
    top: 200px;
    left: 596px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}
.ldio-japzwhp0h9j div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-japzwhp0h9j 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldio-japzwhp0h9j div:nth-child(1) {
  border-color: #e90c59;
  animation-delay: 0s;
}
.ldio-japzwhp0h9j div:nth-child(2) {
  border-color: #46dff0;
  animation-delay: -0.5s;
}
.loadingio-spinner-ripple-wsf5cxo48ch {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-japzwhp0h9j {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-japzwhp0h9j div {
  box-sizing: content-box;
}
