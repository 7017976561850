.smallButton {
  width: 60px !important;
  height: 60px !important;

  transition: width 1s;
}
.hideWords {
  opacity: 0 !important;
  transition: opacity 0.1s;
}
