.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  right: -2px !important;
}
.css-16x927t-MuiFormControl-root {
  border-radius: 40px !important;
  background-color: #f1f1f1 !important;
}
.item1MenuList {
  display: none;
}
.item1:hover > .item1MenuList {
  display: block;
}
.item1:hover .pass .item1Icon {
  transform: rotate(180deg);
}
/* .item1SmallMenuList {
  display: none;
}
.item1Small:hover .item1SmallMenuList {
  display: block;
} */
.MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input.MuiInputBase-inputSizeSmall.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  background-color: #f1f1f1;
}
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  right: -12px !important;
}
/* .item2MenuList {
  display: none;
}
.item2:hover > .item2MenuList {
  display: block;
} */
/* .menuHeight {
  height: auto;
} */

.iconFlip {
  transform: rotate(90deg);
}

/*   Sign up Button */
.css-11zrz7-MuiButtonBase-root-MuiButton-root {
  border-radius: 30px !important;
}
/*   Sign in Button */
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  border-radius: 30px !important;
}
