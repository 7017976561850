.showModal {
  /* width: 50% !important; */
  max-width: 50% !important;
  max-height: 90vh !important;
  min-height: 90vh !important;
  top: 10.5% !important;
  transition: all 0.5s;
}

@media (max-width: 700px) {
  .showModal {
    max-width: 100% !important;
  }
}
