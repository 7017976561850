.homeSlick .slick-prev:before,
.homeSlick .slick-next:before {
  font-size: 30px !important;
  color: #f12c2c !important;
}
.homeSlick .slick-prev {
  left: -35px !important;
  top: 42% !important;
  z-index: 5 !important;
}
.homeSlick .slick-next {
  right: -25px !important;
  z-index: 5 !important;
  top: 42% !important;
}

@media only screen and (min-width: 360px) and (max-width: 900px) {
  .homeSlick .slick-next {
    right: -5px !important;
  }
  .homeSlick .slick-prev {
    left: -15px !important;
  }
}
