.gallery-icon-box {
    margin-top:"30px";
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.item-icon-box {
    display: flex;
    width: 25%;
    margin-top: 30px;
}
.item-icon .icons {
    color: #00c194;
    font-size: 26px;
    height: 52px;
    width: 52px;
    border-style: solid;
    border-width: 1px;
    border-color: #e8e9f1;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0px 4px 18px 0px rgb(188 192 202 / 26%);
    padding: 7px 7px;
    display: inline-block;
    line-height: 46px;
    text-align: center;
}
.item-number {
    margin-left: 15px;
}
.item-number span {
    color: #878c9f;
    font-size: 15px;
}
.item-number .deep-clr {
    color: #212121;
    font-weight: 600;
    font-size: 18px;
}
.item-number1 {
    margin-top: 30px;
}
.item-number1 span {
    margin-top:"30px";
    color: #878c9f;
    font-size: 15px;
}