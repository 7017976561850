/* .slick-dots {
  bottom: 4px;
}
.slick-dots li button:before {
  color: #fdfdfd;
}
.slick-next {
  right: 4%;
}

.slick-prev,
.slick-next {
  top: 50%;
  z-index: 2;
}
.slick-prev {
  left: 4%;
}
.slick-prev:before,
.slick-next:before {
  color: #fdfdfd;
  opacity: 0;
  transition: opacity 0.5s;
}
.service:hover .slick-prev:before,
.service:hover .slick-next:before {
  opacity: 1;
  transition: opacity 0.5s;
} */

.serviceSlick .slick-dots {
  bottom: 4px;
}
.serviceSlick .slick-dots li button:before {
  color: #fdfdfd;
}
.serviceSlick .slick-next {
  right: 4% !important;
}

.serviceSlick .slick-prev,
.serviceSlick .slick-next {
  top: 50%;
  z-index: 2;
}
.serviceSlick .slick-prev {
  left: 4% !important;
}
.serviceSlick .slick-prev:before,
.serviceSlick .slick-next:before {
  color: #fdfdfd !important;
  opacity: 0 !important;
  transition: opacity 0.5s;
}
.service:hover .serviceSlick .slick-prev:before,
.service:hover .serviceSlick .slick-next:before {
  opacity: 1 !important;
  transition: opacity 0.5s;
}
