.selected {
  background-color: #e0e8bf !important;
}
.selectedPaymentGateway {
  background: linear-gradient(35deg, #494949, #313131);
  color: white;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  border-radius: 30px;
}
