:root {
  --playerWidth: 100%;
}

.app {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  background-color: black;
  width: var(--playerWidth);
  overflow: hidden;
}
.app:hover .shadow {
  position: absolute;

  border: 1px solid gray;
  background-color: rgba(0, 0, 0, 0.4);

  width: 100%;
  height: 100%;
}

/* Video */

.video {
  width: auto;
}

/* Controls */
.controlsContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app:hover .controlsContainer {
  background-color: transparent;
  width: 100%;
  z-index: 20;
}

.controls {
  height: 100%;
  width: 80%;
  display: none;

  margin: auto;
}

.app:hover .controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controlsPause {
  display: none;
  z-index: 40;
  margin: auto;
}
.app:hover .controlsPause {
  position: absolute;
  top: 50%;
  left: 50%;
}

.controlsIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 10rem;
  margin-right: 10rem;
}

.controlsIcon--small {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 10rem;
  margin-right: 10rem;
}

.timecontrols {
  display: none;

  position: absolute;
  width: 100%;
  bottom: 2rem;
}
.app:hover .timecontrols {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.showTimeBar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.timeDigit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0px 10px;
  box-sizing: border-box;
}

.time_progressbarContainer {
  background-color: gray;
  border-radius: 15px;
  width: 100%;
  height: 4px;
  z-index: 30;
  position: relative;
}

.time_progressBar {
  border-radius: 15px;
  background-color: darkgreen;
  height: 100%;
}

.controlsTime {
  color: white;
}

.progress {
  top: 10px;
  width: 100%;
  height: 20px;
}

.progress .path {
  background-color: #fff;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.progress .knob {
  background-color: crimson;
  width: 7px;
  height: 15px;
  left: 20px;
  border-radius: 2px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s linear;
  pointer-events: none;
  transform: translate(-50%, -50%);
}

.progress .comp {
  position: absolute;
}
