.translate {
  transform: translate3d(0, -80px, 0);
}
.item-category7.MuiBox-root.css-0 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding: 16px 25px;
  display: inline-block;
  background-color: #00c194;
  border: none;
  border-radius: 2px;
  position: relative;
}
.item-category7.MuiBox-root.css-0:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #fff;
  left: 10px;
  top: 24px;
  border: none;
  border-radius: 50%;
}
.item-price7.MuiBox-root.css-0 {
  color: #00c194;
  font-size: 34px;
  font-weight: 600;
}

/* icon list */
.side-button {
  text-align: right;
}
.side-button ul {
  display: inline-flex;
  position: relative;
}
.side-button ul li {
  position: relative;
  list-style: none;
}
.side-button ul li .side-btn {
  font-size: 18px;
  color: #00c194;
  height: 45px;
  width: 45px;
  background: #fff;
  overflow: hidden;
  border: 1px solid #e0e5ee;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  border-radius: 3px;
  border-right: 0;
}
/* input start =============================*/
/* .newsletter-form1 .input-group1 {
    position: relative; 
     border-radius: 30px !important;
} */
.input-group1 {
  border-radius: 15px;
  background-color: #ffffff;
  border: 1px solid #e1e5ee;
  transition: 0.4s;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.newsletter-form1 .input-group1 .form-control1 {
  height: 60px;
  width: 100%;
  background-color: transparent;
  border: none;
  color: #212121;
  padding-left: 25px;
  border-radius: 0 !important;
}
textarea.form-control1.textBox {
  height: 150px !important;
}
/* contact agent button =========== */
.wid-contact-button {
  margin-bottom: 24px;
}
/* .wid-contact-button li {
    display: inline-block;
} */
.wid-contact-button button {
  display: inline-block;
  background-color: #eaf7f4;
  border: none;
  border-radius: 3px;
  color: #00c194;
  font-size: 15px;
  font-weight: 700;
  padding: 7px 18px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  margin-right: 30px;
}
/* -======== under title ========== */
.media {
  margin-bottom: 24px;
}
.item-logo {
  flex-shrink: 0 !important;
}
.media-body {
  margin-left: 1rem !important;
  flex-grow: 1;
  margin-top: 20px;
}
.media-body .item-title {
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 18px;
  color: #00c194;
}
.media-body .item-phn {
  color: #212121;
  font-size: 17px;
  margin-bottom: 3px;
}
.media-body .item-phn span {
  color: #00c194;
}
.media-body .item-mail {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}
