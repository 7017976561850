.blogListCarousel .slick-prev:before,
.blogListCarousel .slick-next:before {
  color: rgb(230, 130, 48);
  opacity: 1;
  font-size: 33px;
}

.blogListCarousel .slick-prev,
.blogListCarousel .slick-next {
  top: -23% !important;
}
.blogListCarousel .slick-prev {
  left: 90%;
}
/* grid top space */
.css-oy84op-MuiGrid-root > .MuiGrid-item {
  padding: 0 !important;
}
@media only screen and (max-width: 600px) {
  .blogListCarousel .slick-prev {
    left: 80%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .blogListCarousel .slick-prev {
    left: 85%;
  }
}
