.showModal {
  height: 85vh !important;
  width: 100% !important;
  transition: all 0.5s;
}
.showCancleButton {
  right: 24px !important;
  transition: all 1s;
}
.showApplyButton {
  bottom: 0px !important;
  transition: all 0.5s;
}
