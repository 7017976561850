.promotedFreelancer .slick-prev:before,
.promotedFreelancer .slick-next:before {
  color: #680d0d !important;
}

.promotedFreelancer .slick-next {
  right: 4% !important;
}

.promotedFreelancer .slick-prev,
.promotedFreelancer .slick-next {
  top: -6% !important;
}
.promotedFreelancer .slick-prev {
  left: 91% !important;
}

/* .slick-prev:before,
.slick-next:before {
  color: #680d0d !important;
}

.slick-next {
  right: 4% !important;
}

.slick-prev,
.slick-next {
  top: -6% !important;
}
.slick-prev {
  left: 91% !important;
} */
