.css-1bgkrxc-MuiButtonBase-root-MuiButton-root {
  border-radius: 1px !important;
}
.c1:hover > .c1Black {
  /* display: block; */
  top: 0;
  height: 100%;
  transition: all 0.5s;
  opacity: 1;
  color: white;
}
.c1:hover > .c1btn {
  /* display: block; */
  top: 75%;
  opacity: 1;
  transition: top 0.5s;
  transition: opacity 0.7s;
}
.c1:hover > .c1Details {
  /* display: block; */
  top: 45%;
  opacity: 1;
  transition: all 0.5s;
  color: white;
}
.c1:hover > .c1logo {
  opacity: 1;
  transition: opacity 1s;
}
.c1:hover > .c1Details .listing {
  background-color: black;
  margin-left: -9px;
  color: white;
}
/* for content */
.c1:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  color: black;

  /* background-color: black; */
  /* opacity: 0.5; */
}
.textT1:hover {
  content: "";
  /* position: absolute; */
  height: 100%;
  width: 100%;
  color: white;

  /* background-color: black; */
  /* opacity: 0.5; */
}
.textT1:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  color: black;

  /* background-color: black; */
  /* opacity: 0.5; */
}
